module.exports = {
	url: {
		api: `https://api.staging-app.nl`,
		login: `https://{{domain}}.staging-app.nl`,
		main: `https://{{domain}}.staging-app.nl`,
		cdn: `https://cdn.staging-app.nl`,
		admin: 'https://admin.staging-app.nl',

	},
	cookiedomain: "staging-app.nl",
	base_domain: "staging-app.nl",
	freelancer_link_code: 'QE5EZES0R5LDWVN',

	rules: {
		required: [(v) => !!v || 'Required field!'],
		name: [(v) => !!v || 'Required field!', (value) => (value && !(value.length > 100)) || 'Maximum 100 characters'],
		email: [(v) => !!v || 'Email address is not valid!', (v) => /.+@.+\..+/.test(v) || 'Invalid email address!'],
		phonenumber: [(v) => !!v || 'Phone number is not valid!', (v) => /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(v) || 'Invalid phone number!'],

	},
	regex: {
		name: /^[a-zA-Z -]{2,50}$/,
		mail: /^.+@.+$/,
		phonenumber: /^\+?[1-9]\d{3,14}$/,
		password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,64}$/,
	},
	allowedredirectdomains: [
		'staging-app.nl',
		'api.staging-app.nl',
		'localhost:8080',
		'localhost:8081',
		'localhost:8082',
		'localhost:8083',
		'localhost:8084',
		'localhost:8085',
		'localhost:8086',
	],
	dateformat: {
		weekday: 'short',
		year: 'numeric',
		day: 'numeric',
		month: 'long',
		hour: 'numeric',
		minute: 'numeric'
	}
}